import React from "react"

import About from "../components/ui/about"
import Seo from "../components/seo"
import Breadcrumbs from "../components/ui/breadcrumbs"

import Layout from "../components/layout"
import img1 from "../../static/images/about/about-3.jpg"
import img2 from "../../static/images/about/about-2.jpg"

class RootIndex extends React.Component {
    render() {
        return (
            <Layout location={this.props.location}>
                <Seo title="About" />

                <div className="container">
                    <Breadcrumbs loc="about" />
                    <h1>About Longevity Lab</h1>
                </div>

                <div className="container flex">
                    <div className="col-2">
                        <h4>
                            Longevity Lab is a Functional Aging Training
                            practice for individuals 60+ and elderly. It was
                            founded in 2017, in New York City, by Functional
                            Aging Specialist and Brain Health Trainer, Ola
                            Stacha-Fleming. Somewhere between group exercise,
                            walking and heavy traditional training or
                            occupational therapy, there’s a void that is not
                            widely acknowledged but is now served by the
                            practices and professionals of Longevity Lab – now
                            based in CT.
                        </h4>
                        <img
                            className="lg mb-2"
                            src={img2}
                            alt="ola training"
                        />
                        <p>
                            Our work is called Functional Aging Training. And
                            our programs are mindfully created based on
                            individual goals and needs. Programs include
                            strength training that gives us stamina and prevents
                            osteoporosis; flexibility and mobility training that
                            prevents strains and pains; balance training to
                            improve body function and lessen possibility of
                            falls and fractures; and cardiovascular exercises to
                            protect us from chronic disease and ailments. All of
                            that, plus brain health training activities, will
                            leave you feeling better, moving better and simply
                            rise up to everyday’s moments!
                        </p>
                        <p>
                            Our mission is to age well with strength and dignity
                            so that we can continue to do the things that keep
                            us happy and healthy for years to come! 
                        </p>
                    </div>
                    <div className="col-2">
                        <img
                            className="lg mb-2"
                            src={img1}
                            alt="ola and friends"
                        />
                        <p>
                            We all know that body movement is crucial to
                            physical and mental well-being, especially as we get
                            older. Longevity Lab presents a formula for living
                            one’s best life ever with a focus on aging with
                            strength and grace. There's no single approach that
                            is right for every individual- that’s why we
                            emphasize customized programs and a curriculum of
                            sustainable fitness regimes for mature adults that
                            can extend healthy life span and total body/mind
                            health. 
                        </p>
                    </div>
                </div>
                <div className="container flex">
                    <div className="col-2 shrinked"></div>
                </div>

                <About nobutton />
            </Layout>
        )
    }
}

export default RootIndex
